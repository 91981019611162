<template >
  <v-container class="my-6 mx-auto">
    <v-card class="py-10">
      <div class="card-header-padding">
        <v-row class="mb-4">
          <v-col cols="auto">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Xандалтыг удирдаx
              </h5>
              <p class="text-sm text-body mb-0">
                Ямар үүрэг линкүүдэд xандаx эрxийг тодорxойлно
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="card-header-padding">
        <v-simple-table class="bborder">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                  "
                >
                  No
                </th>
                <th>Нэр</th>
                <th
                  v-for="dep in accessLinks"
                  :key="dep.id"
                  class="text-center font-weight-bold"
                >
                  {{ dep.name }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(lesson, lindex) in roles" :key="lesson.id + lindex">
                <td
                  style="
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                    width: 1%;
                  "
                >
                  {{ lindex + 1 }}
                </td>
                <td>
                  {{ lesson.name }}
                </td>
                <template v-for="dep in accessLinks">
                  <td
                    :key="lesson.id + lindex + dep.id"
                    class="text-center borderCell"
                    @click="_clickedCell(dep, lesson)"
                    v-if="_isContained(dep, lesson)"
                    style="background: #f5f591; cursor: pointer"
                    :title="dep.name"
                  >
                    <span>+</span>
                  </td>
                  <td
                    class="text-center"
                    :key="lesson.id + lindex + dep.id"
                    v-else
                    @click="_clickedCell(dep, lesson)"
                    :title="dep.name"
                    style="cursor: pointer"
                  >
                    -
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      accessLinks: null,
      roles: null,
    };
  },

  methods: {
    _isContained(dep, lesson) {
      if (lesson.accessLinks) {
        for (const dep2 of lesson.accessLinks) {
          if (dep.id == dep2.id) {
            return true;
          }
        }
      }
      return false;
    },
    _clickedCell(links, role) {
      console.log(links);
      var added = this._isContained(links, role);
      if (added) {
        role.ref.collection("accesses").doc(links.id).delete();
      } else {
        var xx = { name: links.name, index: links.index, ref: links.ref };
        console.log(xx);
        role.ref.collection("accesses").doc(links.id).set(xx, { merge: true });
      }
    },
    async _setupp() {
      this.userData.school.ref
        .collection("xDuties")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.roles = [];
          querySnapshot.forEach((doc) => {
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            lesson.accessLinks = null;
            lesson.ref.collection("accesses").onSnapshot((querySnapshot) => {
              lesson.accessLinks = [];
              querySnapshot.forEach((doc) => {
                let dep = doc.data();
                dep.id = doc.id;
                dep.ref = doc.ref;
                lesson.accessLinks.push(dep);
              });
            });
            this.roles.push(lesson);
          });
        });
      this.userData.school.ref
        .collection("xAccessLinks")
        .onSnapshot((querySnapshot) => {
          this.accessLinks = [];
          querySnapshot.forEach((doc) => {
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            this.accessLinks.push(lesson);
          });
        });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },

  created() {
    this._setupp();
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}
.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
.bborder .borderCell:hover {
  background-color: red;
}
</style>